// Convert Temperature from Celsius to Fahrenheit
const convertTemperature = (temperature) => {
  let celsius = Math.round(temperature);
  let fahrenheit = Math.round(temperature * 1.8 + 32);
  return {
      fahrenheit: fahrenheit,
      celsius: celsius
  };
};

// Convert Speed from ms^-1 to mph
const convertMsToMph = (spd) => {
  let speed;
  if (spd) {
    speed = `${Math.round(spd * 2.236936)} mph`;
  } else if (spd === 0) {
    speed = 'Calm';
  } else {
    speed = 'n/a';
  }
  
  return speed
};

// Convert Wind Direction from degrees to 8-point compass rose
const convertDirection = (wDir) => {
  let direction;
  if (wDir >0 && wDir <=22) {
      direction = { long: 'North', short: 'N' };
  }
  else if (wDir >22 && wDir <=67) {
      direction = { long: 'Northeast', short: 'NE' };
  }
  else if (wDir >67 && wDir <=112) {
      direction = { long: 'East', short: 'E' };
  }
  else if (wDir >112 && wDir <=157) {
      direction = { long: 'Southeast', short: 'SE' };
  }
  else if (wDir >157 && wDir <=202) {
      direction = { long: 'South', short: 'S' };
  }
  else if (wDir >202 && wDir <=247) {
      direction = { long: 'Southwest', short: 'SW' };
  }
  else if (wDir >247 && wDir <=292) {
      direction = { long: 'West', short: 'W' };
  }
  else if (wDir >292 && wDir <=337) {
      direction = { long: 'Northwest', short: 'NW' };
  }
  else if (wDir >337 && wDir <=360) {
      direction = { long: 'North', short: 'N' };
  }
  else if (wDir === 0) {
      direction = { long: '&nbsp;', short: '&nbsp;' };
  }
  else if (wDir === null) {
      direction = { long: '&nbsp;', short: '&nbsp;' };
  }
  else {
      direction = { long: 'Unknown', short: 'Unk' };
  }
  return direction
};

// Get Day of Week
const convertToDayOfWeek = (fullDate) => {
  const day = new Date(fullDate).getDay();

  let dayOfWeek;

  if (day === 0) { 
    dayOfWeek = { long: 'Sunday', short: 'Sun' } 
  }
  else if (day === 1) { 
    dayOfWeek = { long: 'Monday', short: 'Mon' } 
  }
  else if (day === 2) { 
    dayOfWeek = { long: 'Tuesday', short: 'Tue' } 
  }
  else if (day === 3) { 
    dayOfWeek = { long: 'Wednesday', short: 'Wed' } 
  }
  else if (day === 4) { 
    dayOfWeek = { long: 'Thursday', short: 'Thu' }
  }
  else if (day === 5) { 
    dayOfWeek = { long: 'Friday', short: 'Fri' } 
  }
  else if (day === 6) { 
    dayOfWeek = { long: 'Saturday', short: 'Sat' } 
  }
  else { 
    dayOfWeek = { long: 'Unknown', short: 'Null' }
  }

  return dayOfWeek;
};

// iOS fix for date and time conversions
const convertDateForIos = (isoDate) => {
  const arr = isoDate.split(/[- : T]/);
  const fullDate = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
  return fullDate;
};

// Get Hour + AM or PM
const convertToHour = (fullDate) => {
  const militaryHour = new Date(fullDate).getHours();

  let hourOfDay;

  if (militaryHour >= 1 && militaryHour <= 11) {
    hourOfDay = militaryHour + ' AM';
  } else if (militaryHour >= 13 && militaryHour <= 23) {
    hourOfDay = (militaryHour - 12) + ' PM';
  } else if (militaryHour === 12) {
    hourOfDay = militaryHour + ' PM';
  } else if (militaryHour === 0) {
    hourOfDay = '12 AM';
  } else {
    hourOfDay = 'Unknown';
  }

  return hourOfDay;
};

// Get Current Time + AM or PM
const convertToTime = (fullDate) => {
  const militaryHour = new Date(fullDate).getHours();
  const mins = new Date(fullDate).getMinutes() < 10 ? '0' + new Date(fullDate).getMinutes() : new Date(fullDate).getMinutes();

  let hour;
  if (militaryHour >= 13 && militaryHour <= 23) {
    hour = militaryHour - 12;
  } else if (militaryHour === 0 || militaryHour === 12) {
    hour = 12;
  } else if (militaryHour >=1 && militaryHour <= 11) {
    hour = militaryHour;
  } else {
    hour = 'Unknown';
  }

  let amOrPm;
  if (militaryHour >= 0 && militaryHour <= 11) {
    amOrPm = ' AM';
  } else if (militaryHour >= 12 && militaryHour <= 23) {
    amOrPm = ' PM';
  } else {
    amOrPm = 'Unknown';
  }

  return {
    withMins: `${hour}:${mins} ${amOrPm}`,
    withoutMins: `${hour} ${amOrPm}`
  };
};

/* Determine whether current time is before or after a user-specified time
   Time passed into the function must be UTC!

   For example, let's say it is 1830 UTC. 
   Calling the function isIt('after', 18) will return true. */
const isIt = (relation, userDefinedHour) => {
    const today = new Date();
    const militaryHour = today.getHours();

    // Handle errors if user passes in an invalid relation or comparison hour
    if ((relation !== 'before' && relation !== 'after') || (userDefinedHour < 0 || userDefinedHour > 23)) {
        throw new Error('Enter valid parameters');
    }

    if (relation === 'before' && militaryHour < userDefinedHour) {
        return true;
    } else if (relation === 'before' && militaryHour === userDefinedHour) {
        return false;
    } else if (relation === 'before' && militaryHour > userDefinedHour) {
        return false;
    } else if (relation === 'after' && militaryHour > userDefinedHour) {
        return true;
    } else if (relation === 'after' && militaryHour < userDefinedHour) {
        return false;
    } else if (relation === 'after' && militaryHour === userDefinedHour) {
        return true;
    }
    else {   
        return true;
    }
};

// Determine whether a date is in daylight saving time
const isItDaylightSaving = (todaysDate) => {
    const daylightOffsetNow = new Date(todaysDate).getTimezoneOffset() / 60;
    const daylightOffsetJul = new Date('July 1').getTimezoneOffset() / 60;

    let daylightSaving;
    if (daylightOffsetNow > daylightOffsetJul) {
        daylightSaving = false;
    } else {
        daylightSaving = true;
    }

    return daylightSaving;
};

// Determine whether 'Day 0' in the UDFD should say "TODAY" or the next day
const todayOrTomorrow = (date) => {
  let day0;
  if (isIt('before', 19) && (isItDaylightSaving(Date.now()))) {
    day0 = 'Today';
  } else if (isIt('before', 18) && (!isItDaylightSaving(Date.now()))) {
    day0 = 'Today';
  } else {
    day0 = convertToDayOfWeek(date).long.toUpperCase();
  }
  
  return day0;
};

// Find and return a number within any string
const findNumberInString = (str) => {
  const regEx = /\d+/;
  const match = str.match(regEx);

  if (match) {
    return `${parseInt(match[0])}%`;
  } else {
    return '&nbsp;';
  }
};

// If the word "day" is found in the string, return true
// Else, return "false" (meaning it's nighttime)
const isItDaytimeMETAR = (str) => {
  const regEx = /\b(\w*day\w*)\b/g;
  const match = str.match(regEx);
  return match ? Boolean(match[0]) : false;
};

export { convertTemperature, convertMsToMph, convertDirection, convertToDayOfWeek, 
         convertDateForIos, convertToHour, convertToTime, isIt, isItDaylightSaving, 
         todayOrTomorrow, findNumberInString, isItDaytimeMETAR };