//import '@babel/polyfill';
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { currentWeatherBug } from './javascripts/bug';

const userDefaultLocation = localStorage.getItem('userDefaultLocation');

if (!userDefaultLocation) {
  // Display Columbia by default
  currentWeatherBug(34.0, -81.03, 'Columbia, SC');
} else {
  // Plot weather bug for last saved location
  const savedLocation = JSON.parse(localStorage.getItem('userDefaultLocation'));
  const { lat, lon, address, cityStateZip } = savedLocation;
  const cityState = `${cityStateZip[0]}, ${cityStateZip[1]}`;
  currentWeatherBug(lat, lon, cityState);
}