const retrieveUDFD = async (lat, lon, days, address = 'your present location') => {
  return fetch(`https://pbsweather.org/shared/server/udfd.php?lat=${lat}&lon=${lon}&days=${days}`)
  .then(response => response.json())
  .catch(error => console.log(error));
};

const retrieveUDFDHourly = async (lat, lon, hours) => {
  return fetch(`https://pbsweather.org/shared/server/udfd_hourly.php?lat=${lat}&lon=${lon}&hours=${hours}`)
  .then(response => response.json())
  .catch(error => console.log(error))
}

const retrieveNDFDHourly = async (lat, lon, hours, address = 'your present location') => {
  return fetch(`https://pbsweather.org/shared/server/hourly.php?lat=${lat}&lon=${lon}&hours=${hours}`)
  .then(response => response.json())
  .catch(error => console.log(error));
};

const retrieveMETAR = async (lat, lon, address = 'your present location') => {
  return fetch(`https://pbsweather.org/shared/server/metar.php?lat=${lat}&lon=${lon}`)
  .then(response => response.json())
  .catch(error => console.log(error))
};

const retrieveMETARNearest = async (lat, lon) => {
  return fetch(`https://pbsweather.org/shared/server/metar_nearest.php?lat=${lat}&lon=${lon}`)
  .then(response => response.json())
  .catch(error => console.log(error))
};

const retrieveWeatherSTEM = async () => {
  return fetch('https://pbsweather.org/shared/server/wxstem.php')
  .then(response => response.json())
  .catch(error => console.log(error))
};

export { retrieveUDFD, retrieveNDFDHourly, retrieveMETAR, retrieveWeatherSTEM, 
          retrieveMETARNearest, retrieveUDFDHourly };