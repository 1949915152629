const getIcon = (value) => {
  const icon_base_url = 'https://pbsweather.org/wp-content/uploads/wxicons';
  const path = `${icon_base_url}/${value}.png`;
  return path;
};

const getMetarIcon = (value, isDaylight) => {
  const icon_base_url = 'https://pbsweather.org/wp-content/uploads/wxicons';
  if (isDaylight) {
    return `${icon_base_url}/${value}.png`;
  } else {
    return `${icon_base_url}/${value}-night.png`
  }
}

const convertNWSPhraseToIcon = (phrase, isDaytime) => {
  const icon_base_url = `https://pbsweather.org/wp-content/uploads`;

  let iconPhrase = phrase.split(' ').join('-');
  iconPhrase = iconPhrase.replace('/', '-').toLowerCase();
  
  let path;
  if (isDaytime) {
    path = `${icon_base_url}/wxicons-nws-day/${iconPhrase}.png`;
  } else if (!isDaytime) {
    path = `${icon_base_url}/wxicons-nws-night/${iconPhrase}.png`;
  } else {
    path = 'https://pbsweather.org/wp-content/uploads/blank.png';
  }

  return path;
};

export {  getIcon, convertNWSPhraseToIcon, getMetarIcon };
