import { retrieveMETARNearest } from '../../../shared/javascripts/providers';
import { convertTemperature } from '../../../shared/javascripts/helpers';
import { getIcon } from '../../../shared/javascripts/icons';

const currentWeatherBug = (lat, lon, cityStateZip) => {
  retrieveMETARNearest(lat, lon)
    .then(observation => {
      // Parse data from Baron
      const icon = observation.metars.data.weather_code.value;
      const temperature = observation.metars.data.temperature.value;

      // Point toward the weather bug div in the DOM
      const wxbug = document.getElementById('wxbug');

      // Create span with id and contents for user location
      const spanUserLocation = document.createElement('span');
      spanUserLocation.setAttribute('id', 'user-location');
      spanUserLocation.innerHTML = `${cityStateZip}&nbsp;`;

      // Create span with id and contents for temperature
      const spanTemperature = document.createElement('span');
      spanTemperature.setAttribute('id', 'baron-temperature-00');
      spanTemperature.innerHTML = `${ convertTemperature(temperature).fahrenheit }&deg;&nbsp;`;

      // Create span with id and contents for icon display
      const spanIcon = document.createElement('span');
      const image = document.createElement('img');
      image.setAttribute('id', 'baron-icon-00');
      image.src = getIcon(icon);
      image.width = '30';
      image.height = '30';
      spanIcon.appendChild(image);
      
      // Append all spans to the main weatherbug div
      wxbug.appendChild(spanUserLocation);
      wxbug.appendChild(spanTemperature);
      wxbug.appendChild(spanIcon);
    })
    .catch(error => console.log(error))
};

export { currentWeatherBug };